import { withPageAuthRequired as withAuth0PageAuthRequired } from "@auth0/nextjs-auth0";
import { useToast } from "@resource/atlas";
import { useFlags } from "@resource/client-ffs";
import { CustomerPlanEnum } from "@resource/common";
import BasePathEnum from "enums/BasePathEnum";
import FeatureFlags from "generated/FeatureFlags";
import _ from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { GetLayout, withConditionalLayout } from "utils/next-layout";
import { PermissionEnum, StaffRoleEnum } from "utils/permissions";

import { useAuthContext } from "./context";

const redirectForRoutesPriorToProspectOnboarding = [
  "/jobs",
  "/insights",
  "/feedback",
  "/surveys",
  "/greenhouse-candidate",
  "/extension",
  "/settings/configuration",
];
export interface PageAuthOptions {
  requiredPermissions?:
    | PermissionEnum[]
    | PermissionEnum
    | ((
        plan: CustomerPlanEnum
      ) => PermissionEnum | PermissionEnum[] | undefined);
  requiredPlan?: CustomerPlanEnum;
  staffRoleRequired?: StaffRoleEnum;
  redirectPath?: string;
  getLayout?: GetLayout;
}

/**
 * A authentication wrapper that allows passing in a layout function so that
 * we can maintain the tree across routes.
 */
const withPageAuthRequired = <T,>(page: T, options?: PageAuthOptions) => {
  const {
    staffRoleRequired,
    requiredPermissions,
    requiredPlan,
    getLayout = _.identity,
  } = options ?? {};

  return withConditionalLayout(getLayout, () => {
    const router = useRouter();
    const path = router.pathname;
    const { [FeatureFlags.ENFORCE_PERMISSIONS]: enforcePermissionsFlag } =
      useFlags();
    const authContext = useAuthContext();
    const { sendToast } = useToast();

    useEffect(() => {
      const listener = (ev: MessageEvent) => {
        if (typeof ev.data === "object") {
          if (ev.data.command === "toast") {
            sendToast(ev.data.message, ev.data.options);
          }
        }
      };
      window.addEventListener("message", listener);
      return () => window.removeEventListener("message", listener);
    }, [sendToast]);

    const redirectPath = useMemo(() => {
      const {
        user: currentUser,
        plan: { name: plan = CustomerPlanEnum.SelfServe } = {},
        loading,
        checkStaffRole,
        checkCustomerPlan,
        checkRolePermissions,
        onboardingComplete,
        error,
      } = authContext;

      if (error) return "/_error";

      if (loading) {
        return undefined;
      }

      // User is unauthenticated
      if (!currentUser) {
        const returnToOrArr = router.query.returnTo || router.asPath;
        const returnTo = Array.isArray(returnToOrArr)
          ? returnToOrArr[0]
          : returnToOrArr;
        return `/login?returnTo=${encodeURIComponent(returnTo)}`;
      }

      // Check Staff Permissions
      if (staffRoleRequired && !checkStaffRole(staffRoleRequired)) {
        return "/invalid-permissions";
      }

      // Check Required Plan
      if (requiredPlan) {
        const valid = checkCustomerPlan(requiredPlan);

        if (!valid) {
          return "/upsell";
        }

        // Platform setup incomplete redirects
        if (plan === CustomerPlanEnum.Platform) {
          const isAtsConfigured =
            !!currentUser?.currentOrganization?.customer.atsIntegration;
          const prospectOnboardingComplete = Boolean(
            currentUser?.currentOrganization?.prospectOnboardingComplete
          );
          const isAdmin = checkRolePermissions(
            PermissionEnum.ORGANIZATION_INTEGRATIONS_WRITE
          );

          const disallowPriorToProspectOnboarding =
            _(redirectForRoutesPriorToProspectOnboarding)
              .map((prefix) => path.startsWith(prefix))
              .some() || path === "/";

          if (!isAtsConfigured && disallowPriorToProspectOnboarding) {
            if (!path.startsWith("/settings/integrations") && isAdmin) {
              sendToast(
                "Your account is not fully set up. Please connect your ATS to continue.",
                {
                  variant: "error",
                }
              );
              return "/settings/integrations";
            }
            return "/settings/company";
          }

          if (
            !prospectOnboardingComplete &&
            disallowPriorToProspectOnboarding
          ) {
            return "/settings/company";
          }
        }
      }

      // Check User Permissions
      const perms =
        typeof requiredPermissions === "function"
          ? requiredPermissions(plan as CustomerPlanEnum)
          : requiredPermissions;

      if (!onboardingComplete) {
        if (path.startsWith("/new-user")) {
          return undefined;
        }
        return "/new-user";
      }

      if (perms && !checkRolePermissions(perms) && enforcePermissionsFlag) {
        return "/invalid-permissions";
      }

      // Global onboarding redirects
      if (
        currentUser?.currentUserMembership?.hasLimitedAccess &&
        !currentUser?.pitchPageOnboardingComplete &&
        !path.startsWith(BasePathEnum.Onboarding)
      ) {
        return BasePathEnum.Onboarding;
      }

      return undefined;
    }, [authContext, path, router, sendToast, enforcePermissionsFlag]);

    if (redirectPath) {
      router.replace(redirectPath);
    }

    return !authContext.loading && !redirectPath;
  })(withAuth0PageAuthRequired(page));
};

export default withPageAuthRequired;
